import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { string } from 'prop-types';

import css from './ListingPage.module.css';
import ListingField from './ListingField';


const SectionEngagement = props => {
  const {
    reasonForCoaching,
    coachingFormat,
    goalsOfEngagement,
    explainFurtherContext,
    howManyOneHourSessions
  } = props;

  return (
    <div className={css.sectionDescription}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.engagement" />
      </h2>
      <div className={css.row}>
        <ListingField id="ListingPage.reasonForCoaching" value={reasonForCoaching} />
        <ListingField id="ListingPage.coachingFormat" value={coachingFormat} />
      </div>
      <ListingField
        className={css.questionField}
        id="ListingPage.goalsOfEngagement"
        value={goalsOfEngagement}
      />
      <ListingField
        className={css.questionField}
        id="ListingPage.explainFurtherContext"
        value={explainFurtherContext}
      />
      <ListingField
        className={css.questionField}
        id="ListingPage.howManyOneHourSessions"
        value={howManyOneHourSessions}
      />
    </div>
  );
};

SectionEngagement.propTypes = {
  reasonForCoaching: string,
  coachingFormat: string,
  goalsOfEngagement: string,
  explainFurtherContext: string,
  howManyOneHourSessions: string
};

export default SectionEngagement;
